import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-mesquite-texas.png'
import image0 from "../../images/cities/scale-model-of-mesquite-convention-&-visitors-bureau-in-mesquite-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Mesquite'
            state='Texas'
            image={image}
            lat='32.7629'
            lon='-96.5888'
            attractions={ [{"name": "Mesquite Convention & Visitors Bureau", "vicinity": "757 N Galloway Ave, Mesquite", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 32.773743, "lng": -96.59996100000001}] }
            attractionImages={ {"Mesquite Convention & Visitors Bureau":image0,} }
       />);
  }
}